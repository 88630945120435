<script>
  import { getChartColorsArray } from "@/utils/charts";
  import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
  export default {
    name: "line-chart",
    components: {
      Vue3ChartJs,
    },
    props: {
        labels:{
            type: Array,
            default: () => [],
        },
        data:{
            type: Array,
            default: () => [],
        },
        year:{
            type: Number,
            default: 2024,
        },
        title:{
            type: String,
            default: "Epargne",
        },
    },
    data(){
      return {
        
      }
    },
    computed: {
      line(){
        return {
          type: "line",
          data: {
            labels: this.labels,
            datasets: [{
              label: "Epargne " + this.year,
              fill: true,
              lineTension: 0.5,
              backgroundColor: getChartColorsArray('["--vz-primary-rgb, 0.2"]'),
              borderColor: getChartColorsArray('["--vz-primary"]'),
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#405189",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "#405189",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.data,
            }],
          },
          options: {
            x: {
              ticks: {
                font: {
                  family: 'Poppins',
                },
              },
            },
            y: {
              ticks: {
                font: {
                  family: 'Poppins',
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  // This more specific font property overrides the global property
                  font: {
                    family: 'Poppins',
                  }
                }
              },
            },
          },

        };
      }
    },
  }
</script>

<template>
  <div class="col">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ title }}</h4>
        <!-- Line Chart -->
        <vue3-chart-js
            class="chartjs-chart"
            v-bind="{ ...line }"
        ></vue3-chart-js>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
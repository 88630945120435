<script>
// import { getChartColorsArray } from "@/utils/charts";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
export default {
  name: "pie-chart",
  components: {
    Vue3ChartJs,
  },
  props: {
    labels:{
      type: Array,
      default: () => ['female'],
    },
    data:{
      type: Array,
      default: () => [20],
    },
    year:{
      type: Number,
      default: 2024,
    },
    title:{
      type: String,
      default: "",
    },
  },
  data(){
    return {

    }
  },
  methods:{
    generateColors() {
      const count = this.labels.length;
      const baseColors = [
        '#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545',
        '#fd7e14', '#ffc107', '#28a745', '#20c997', '#17a2b8'
      ];

      const lightenDarkenColor = (col, amt) => {
        let usePound = false;

        if (col[0] === "#") {
          col = col.slice(1);
          usePound = true;
        }

        let num = parseInt(col, 16);

        let r = (num >> 16) + amt;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let g = ((num >> 8) & 0x00FF) + amt;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        let b = (num & 0x0000FF) + amt;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        return (usePound ? "#" : "") + (r.toString(16).padStart(2, '0')) + (g.toString(16).padStart(2, '0')) + (b.toString(16).padStart(2, '0'));

      }

      const colors = [];

      for (let i = 0; i < count; i++) {
        const baseColor = baseColors[i % baseColors.length];
        const adjustment = Math.floor(i / baseColors.length) * 15 - 30; // Ajuste la luminosité
        colors.push(lightenDarkenColor(baseColor, adjustment));
      }

      return colors;
    }
  },
  computed: {
    pie(){
      return {
        type: "pie",
        data: {
          labels: this.labels,
          datasets: [{
            data: this.data,
            backgroundColor: this.generateColors(),
            hoverBorderColor: "#fff",
          }, ],
        },
        options: {
          maintainAspectRatio: true,
          responsive: true,
        },
      };
    }
  },
}
</script>

<template>
  <div class="col">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ title }}</h4>
        <!-- Line Chart -->
        <vue3-chart-js
            :height="140"
            class="chartjs-chart"
            v-bind="{ ...pie }"
        ></vue3-chart-js>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>